import { request,METHOD } from '@/utils/request.js'
const apiList = {
  login: '/login', // 登录
  companyRoleList:'/system/user/selectUserCompanyList', // 查询用户所在企业列表
  roleList:'/system/user/selectUserCompanyRoleList', //查询用户所在企业角色列表
  cutCompanyRole:'/system/user/cutCompanyRole', //切换用户角色
  selectCompanyInfo:'/business/subReqWages/selectCompanyInfo', //企业基本信息统计
  selectProjectData:'/business/subReqWages/selectProjectData', //项目统计数据
  abnormalProjectTotal:'/business/subReqWages/abnormalProjectTotal', //项目统计数据
  projectWorkerTotal:'/business/subReqWages/projectWorkerTotal', //首页项目工人统计
  selectExamimeTotal:'/business/subReqExamime/selectExamimeTotal', //首页考核情况统计
  selectProjectInfo:'/business/subReqWages/selectProjectInfo', //项目基本信息统计
  selectProjectPersonTotal:'/business/subReqPerson/selectProjectPersonTotal', //项目工人统计
  selectPersonArrearsWages:'/business/personArrearsWages/selectPersonArrearsWages', //项目人员欠薪明细
  selectProjectSettleTotal:'/business/subReqWages/selectProjectSettleTotal', //内页项目结算统计
  screenSwitchInfo:'/business/companyScreenConfig/screenSwitchInfo', //大屏切换
  
}
/**
 * 查询用户所在企业列表
 * @param [string] status 搜索-状态
 */
export function companyRoleList(parameter) {
  return request({
    url: apiList.companyRoleList,
    method: METHOD.POST,
    data: parameter,
    isformData:false
  })
}

/**
 * 查询用户所在企业角色列表
 * @param [string] status 搜索-状态
 */
export function roleList(parameter) {
  return request({
    url: apiList.roleList,
    method: METHOD.POST,
    data: parameter,
    isformData:false
  })
}

/**
 * 切换用户角色
 * @param [string] status 搜索-状态
 */
export function cutCompanyRole(parameter) {
  return request({
    url: apiList.cutCompanyRole,
    method: METHOD.POST,
    data: parameter,
    isformData:false
  })
}

/**
 * 企业基本信息统计
 * @param [string] status 搜索-状态
 */
export function selectCompanyInfo(parameter) {
  return request({
    url: apiList.selectCompanyInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}
/**
 * 项目统计数据
 * @param [string] status 搜索-状态
 */
export function selectProjectData(parameter) {
  return request({
    url: apiList.selectProjectData,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

/**
 * 异常项目统计
 * @param [string] status 搜索-状态
 */
export function abnormalProjectTotal(parameter) {
  return request({
    url: apiList.abnormalProjectTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

/**
 * 首页项目工人统计
 * @param [string] status 搜索-状态
 */
export function projectWorkerTotal(parameter) {
  return request({
    url: apiList.projectWorkerTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}


/**
 * 首页考核情况统计
 * @param [string] status 搜索-状态
 */
export function selectExamimeTotal(parameter) {
  return request({
    url: apiList.selectExamimeTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

/**
 * 项目基本信息统计
 * @param [string] status 搜索-状态
 */
export function selectProjectInfo(parameter) {
  return request({
    url: apiList.selectProjectInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

/**
 * 项目工人统计
 * @param [string] status 搜索-状态
 */
export function selectProjectPersonTotal(parameter) {
  return request({
    url: apiList.selectProjectPersonTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

/**
 * 项目工人统计
 * @param [string] status 搜索-状态
 */
export function selectPersonArrearsWages(parameter) {
  return request({
    url: apiList.selectPersonArrearsWages,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

/**
 * 内页项目结算统计
 * @param [string] status 搜索-状态
 */
export function selectProjectSettleTotal(parameter) {
  return request({
    url: apiList.selectProjectSettleTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}


export function screenSwitchInfo(parameter) {
  return request({
    url: apiList.screenSwitchInfo,
    method: METHOD.POST,
    data: parameter,
    isformData:false
  })
}