<template>
  <div class="bg" :style="{backgroundImage:'url('+bg1+')'}">
    <div class="header">
      <div class="logo-box">
        <div class="weather">
          <div @click="toTabPage()" class="return">
            <img src="@/assets/screen/goBack1.png" alt="">
          </div>
        </div>
        <div class="logo" @click="showModel()">
          <img v-if="companyLogo" :src="companyLogo" alt="">
          <div v-else class="logo-title">{{companyName}}</div>
        </div>
      </div>
      <div class="title">
        <img class="title-img" :src="projectTitle" alt="">
      </div>
      <div class="date-box" >
        <div class="left">
          <p class="time">{{moment(dataTime).format('HH:mm:ss')}}</p>
          <p class="date">{{moment(dataTime).format('YYYY年MM月DD日')}}</p>
        </div>
        <!-- <div class="interval"></div> -->
        <!-- <div class="logout" @click="logout">
          <img src="@/assets/screen/logout.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="content">
      <p class="projectName">{{name}}</p>
      <div class="tableBox">
        <ul class="flex tabletitle">
          <li>序号</li>
          <li>{{type == 3 ? '班组名称' : '姓名'}}</li>
          <li>{{type == 3 ? '人数' : '身份'}}</li>
          <li>所属劳务公司</li>
          <li>拖欠金额(元)</li>
        </ul>
        <div v-if="tableData.length > 0" class="tableScroll swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in tableData" :key="index">
              <ul class="content flex">
                <li>{{index > 8 ? (index + 1) : '0'+(index + 1)}}</li>
                <!-- <li>{{type == 1 ? item.fullName : type == 2 ? item.workerName : item.groupChatName}}</li> -->
                <li>{{item.workerName}}</li>
                <li v-if="type==3">{{item.personNum}}</li>
                <li v-else>{{item.roleId}}</li>
                <li>{{item.companyName ? item.companyName : '——'}}</li>
                <li>{{item.arrearsMoney}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else class="tableScroll">
          <img class="noImg" src="../../assets/screen/noData.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from "moment"
import Swiper from 'swiper'

import { 
  selectPersonArrearsWages
} from "../../services";
export default {
  name:"home",
  components:{
    
  },
  data(){
    return{
      Vue,
      moment,
      // METHOD,
      title:require("@/assets/screen/title.png"),
      bg1:require("@/assets/screen/bg1.png"),
      bg2:require("@/assets/screen/bg2.png"),
      projectTitle:require('@/assets/screen/projectTitle.png'),
      dataTime:"2021-05-01 00:00:00",
      tableftEchart:true,
      tableData: [
        {}
      ], // 表格数据
      id: '' ,// 工程id
      type: null, //类型 1领队 2 工人 3 班组
      name: '', // 项目名称
      companyName: '', // 公司名称
      companyLogo: '' // 公司logo
    }
  },
  filters:{
    role(res){
      if(res == 8) return '领队'
      if(res == 9) return '组长'
      if(res == 10) return '工人'
    }
  },
  methods:{
    getDate(){
      const _this = this
      setInterval(()=>{
        _this.dataTime = new Date()
      },1000)
    },
    // 退出登录
    // logout() {
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('companyName')
    //   this.$router.push({ path: "/" })
    // },
    // 返回上一级
    toTabPage(){
      this.$router.push({ path: "/" })
    },
    // 获取表格数据
    getTableData(){
      selectPersonArrearsWages({
        engineeringId: this.id,
        type: this.type
      }).then(res=>{
        if(res.code == 200){
          this.tableData = res.data
          this.$nextTick(()=>{
            if (res.data.length > 8) {
              new Swiper('.swiper-container', {
                direction: 'vertical',
                speed: 3000, // 速度
                autoplay: true,
                loop:true,
                slidesPerView: 8, // 每页显示几个slide
              })
            }
          })
        }
      })
    }
  },
  created(){
    this.getDate()
  },
  mounted(){
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.name = this.$route.query.name
    this.companyName = this.$route.query.companyName
    this.companyLogo = this.$route.query.companyLogo
    this.getTableData()
  },
  destroyed(){},
    
}
</script>
<style scoped lang="less">
  .bg{
    max-width: 19.20rem;
    min-height: 10.8rem;
    background-repeat:no-repeat;
    background-size:100% 100%;
    .header{
      height: 1rem;
      width: 100%;
      position: relative;
      .logo-box{
        width: 3.3rem;
        height: .5rem;
        position: absolute;
        top: .45rem;
        left: .34rem;
        display: flex;
        flex-wrap: wrap;
        .logo{
           width: 2.0rem;
          height: .5rem;
          font-size: .2rem;
          line-height: .38rem;
          color: #d0fffd;
        }
        .logo-title{
          width: 1.5rem;
          font-size: 0.16rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
        }
        .weather{
          margin-right: .30rem;
          width: 1.0rem;
          height: .50rem;
          text-align: right;
          font-size: .20rem;
          color: #d0fffd;
          .return{
            position: absolute;
            top: -0.2rem;
            width: 1.2rem;
            height: 0.57rem;
            cursor: pointer;
            img{
              width: 100%;
            }
          }
        }
        .interval{
          border-right: .03rem solid #236495;
          height: .19rem;
          margin-top: .08rem;
          margin-left: .3rem;
        }
      }
      .title{
        position: absolute;
        width: 3.7rem;
        height: .5rem;
        left: 50%;
        margin-left:-1.85rem;
        margin-top: .32rem;
        .title-img{
          width:100%
        }
      }
      .date-box{
        width: 3.30rem;
        position: absolute;
        top: 0.35rem;
        right: 0.25rem;
        display: flex;
        flex-wrap: wrap;
        font-size: .20rem;
        color: #d0fffd;
        justify-content: space-between;
        .left{
          position: relative;
          text-align:left;
        }
        .time{
          font-size: .26rem;
        }
        .date{
          font-size: .18rem;
        }
        .interval{
          border-right: .03rem solid #236495;
          height: .19rem;
          margin: 0 .1rem;
          margin-top: .2rem;
        }
        .logout{
          position: relative;
          top: .1rem;
          right: .1rem;
          width: 0.88rem;
          height:.37rem;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
    }
    .content{
      position: relative;
      padding-top: 1.70rem;
    }
  }
  li {
    list-style: none;
  }
  .flex{
    display: flex;
    justify-content: space-between;
  }
  .projectName{
    font-size: .24rem;
    font-weight: 400;
    color: #58D2FF;
    text-align: left;
    padding-left: 1.4rem;
  }
  .noImg{
    width: 2.26rem !important;
    height: 2.37rem !important;
    margin-top: 1rem;
  }
  .tableBox{
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    li:nth-child(1){
      width: 12%;
    }
    li:nth-child(2){
      width: 20%;
    }
    li:nth-child(3){
      width: 25%;
    }
    li:nth-child(4){
      width: 25%;
    }
    li:nth-child(5){
      width: 25%;
    }
    .tabletitle {
      margin: .18rem auto 0 auto;
      padding: 0 .22rem .12rem .22rem;
      li{
        color:#D0FFFD;
        font-size: .16rem;
      }
    }
    .tableScroll{
      height: 4.98rem;
      overflow: hidden;
    }
    .content{
      height: .62rem;
      line-height: .62rem;
      padding: 0 .2rem 0 .18rem;
      margin: 0 auto 0 .06rem;
      li{
        font-size: .14rem; 
        color: #D0FFFD;
      }
    }
  }
</style>